:root {
    --typography-size-base: 14px;
    --spacing-xs: calc(0.75 * var(--typography-size-base));
    --spacing-sm: calc(1 * var(--typography-size-base));
    --spacing-md: calc(1.5 * var(--typography-size-base));
    --spacing-lg: calc(2 * var(--typography-size-base));
    --spacing-l-xl: calc(3 * var(--typography-size-base));
    --spacing-xl: calc(4 * var(--typography-size-base));

    --card-spacing-s: calc(2 * var(--typography-size-base)); //32px
    --card-spacing-m: calc(6 * var(--typography-size-base)); //96px
    --card-spacing-l: calc(12 * var(--typography-size-base)); //192px
    --card-spacing-xl: calc(20 * var(--typography-size-base)); //320px
    --card-spacing-xxl: calc(24 * var(--typography-size-base)); //384px
    --card-spacing-xxxl: calc(30 * var(--typography-size-base)); //480px

    --table-spacing-xs: calc(0.5 * var(--typography-size-base)); //12px
    --table-spacing-s: calc(1 * var(--typography-size-base)); //16px
    --table-spacing-m: calc(4 * var(--typography-size-base)); //64px
    --table-spacing-m-l: calc(5 * var(--typography-size-base)); //80px
    --table-spacing-l: calc(8 * var(--typography-size-base)); //108px
    --table-spacing-x-l: calc(12 * var(--typography-size-base)); //192px
    --table-spacing-xl: calc(16 * var(--typography-size-base)); //256px

    --primary-background-color: #FFFFFF;
    --secondary-background-color: #edf4ff;

    --secondary-font-color: #8D989E;
}