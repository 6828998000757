@import 'https://fonts.googleapis.com/css?family=Inter';
@import 'variables.scss';
@import 'style-classes.scss';

:root {
    font-weight: bold;
    font-family: 'Inter';
    font-size: var(--typography-size-base);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Inter';
    margin: 0;
}

h1 {
    font-size: 32px;
    line-height: 40px;
}

h2 {
    font-size: 20px;
    line-height: 28px;
}

h3 {
    font-size: 14px;
    line-height: 20px;
}

h4 {
    font-size: 14px;
    line-height: 20px;
}

//text and line height

.text-xs {
    font-size: 0.857rem;
    /* 12px */
    line-height: 1.143rem;
    /* 16px */
}

.text-sm {
    font-size: 1rem;
    /* 14px */
    line-height: 1.429rem;
    /* 20px */
}

.text-base {
    font-size: 1.143rem;
    /* 16px */
    line-height: 1.714rem;
    /* 24px */
}

.text-lg {
    font-size: 1.429rem;
    /* 20px */
    line-height: 2rem;
    /* 28px */
}

.text-xl {
    font-size: 32px;
    line-height: 40px;
}

//font weight

.font-regular {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.font-bold {
    font-weight: 700;
}

.form-group {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;
}

.gap-x-12 {
    column-gap: 12px;
}

.gap-x-24 {
    column-gap: 24px;
}

.col-span-2 {
    grid-column: span 2 / span 2;

    @media (max-width: 768px) {
        grid-column: span 1 / span 1;
    }
}

.form-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: space-between;

}

.form-input-label:first-child {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

//moved here so it overides the .grid-cols-2, .grid-cols-3, .grid-cols-4 classes
@media (max-width: 768px) {
    .form-group {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.section-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
    border-radius: var(--default-element-radius);
    background-color: var(--secondary-background-color);
    border: 1px solid var(--borders-color);

    .elements-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 12px;
    }

    .elements-wrapper>* {
        margin: 0;
    }
}

.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.tooltip-container {
    text-align: center;
    z-index: 112;
    position: fixed;
    padding: 8px 16px;
    border: thin solid var(--borders-color);
    border-radius: var(--default-element-radius);
    font-size: 0.66rem;
    font-weight: 600;
    font-family: inherit;
    width: auto;
    background-color: var(--primary-background-color);
    color: var(--primary-text-color);
    box-sizing: border-box;
    pointer-events: none;
}

//overlay style (blurred panel)
.overlay {
    z-index: 1101;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: var(--shadow-color);
}




.text-danger {
    text-align: start;
    margin-top: 0.5rem;
    margin-left: 2rem;
    color: var(--color-orange) !important;
    font-size: .7rem;
    white-space: pre-wrap;
}

.mat-icon {
    height: 100% !important;
    width: 100% !important;
    max-height: 20px !important;
    max-width: 20px !important;
}

//scrollbar style

/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c6c6c6;
    border-radius: 10px;
}