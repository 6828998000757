/* You can add global styles to this file, and also import other style files */

//@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "assets/theme.css";
//@import "primeng/resources/primeng.css";
@import './variables.scss';
@import './mixin.scss';
//@import "primeicons/primeicons.css";
@import url('https://fonts.googleapis.com/css2?family=Jomhuria&family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');
@import "./utils/style-class.scss";

body {
    height: 100vh;
    margin: 0;
    font-family: 'inter';
}


.header-message {
    padding: 0.5rem;
    text-align: center;
    animation: slideInFromAbove 0.5s ease forwards;

    &--danger {
        background-color: var(--color-ruby);
        color: white;
    }

    &--warning {
        background-color: var(--color-orange);
        color: white;
    }

    &--success {
        background-color: var(--color-eco-dark);
        color: white;
    }

}

.detail-subpage {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    align-self: center;
}

.border-card {
    padding: 24px;
    background-color: var(--secondary-background-color);
    border: thin solid var(--borders-color);
    border-radius: var(--default-element-radius);
}

.bottom-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px;
    background-color: white;
    border-top: thin solid var(--borders-color);
    display: flex;
    justify-content: end;
}

.hr-custom {
    width: 100%;
    /* Mantiene la linea orizzontale */
    height: 1px;
    /* Definisce lo spessore della linea */
    background-color: var(--borders-color);
    /* Cambia il colore della linea */
    border: none;
    /* Rimuove il bordo predefinito */
    grid-column: 1 / -1;
    /* Se usato in una griglia, occupa tutta la larghezza */
}


.loading-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    background-color: #c6c6c650;
}

.sub-page-content {
    background-color: #EEF3F5;
}

.tab-view-submenu {
    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
        background-color: #EEF3F5;
        color: black;
        border: none;
    }

    .p-tabview .p-tabview-nav {
        border: none;
    }

    .p-tabview-panels {
        padding: 0;
    }
}

.p-button-xs {
    button {
        padding: 0.25rem;
        font-size: 0.25rem;
    }
}


.avatar-image {
    clip-path: circle();
    object-fit: cover;

    &.small {
        height: 30px;
        width: 30px;
    }

    &.medium {
        height: 80px;
        width: 80px;
    }

    &.large {
        height: 120px;
        width: 120px;
    }

    &.contain {
        object-fit: contain;
    }
}

.detail-page {
    width: 100%;
    max-width: 800px;
}

.main-page-table {
    align-self: center;
    border: var(--typography-size-base) solid var(--secondary-background-color);
    border-radius: calc(0.25 * var(--typography-size-base));
}

.table-cell-width-sm {
    width: calc(6 * var(--typography-size-base));
    min-width: calc(6 * var(--typography-size-base));
    max-width: calc(6 * var(--typography-size-base));
}

.table-cell-width-md {
    width: calc(11 * var(--typography-size-base));
    min-width: calc(11 * var(--typography-size-base));
    max-width: calc(11 * var(--typography-size-base));
}

.table-cell-width-lg {
    width: calc(16 * var(--typography-size-base));
    min-width: calc(16 * var(--typography-size-base));
    max-width: calc(16 * var(--typography-size-base));
}

.table-cell-width-xl {
    width: calc(20 * var(--typography-size-base));
    min-width: calc(20 * var(--typography-size-base));
    max-width: calc(20 * var(--typography-size-base));
}


.avatar-image {
    clip-path: circle();
    object-fit: cover;

    &.small {
        height: 30px;
        width: 30px;
    }

    &.medium {
        height: 80px;
        width: 80px;
    }

    &.large {
        height: 120px;
        width: 120px;
    }

    &.contain {
        object-fit: contain;
    }
}

/* Small devices such as large phones (640px and up) */
@media only screen and (min-width: 40em) {

    .detail-header,
    .detail-htab-wrapper,
    .main-page-table,
    .detail-htab-wrapper,
    .detail-header-container,
    .detail-subpage {
        width: 640px;
    }
}

/* Medium devices such as tablets (768px and up) */
@media only screen and (min-width: 48em) {

    .detail-header,
    .detail-htab-wrapper,
    .detail-header-container,
    .main-page-table,
    .detail-htab-wrapper,
    .detail-subpage {
        width: 768px;
    }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 64em) {

    .detail-header,
    .detail-htab-wrapper,
    .detail-header-container,
    .main-page-table,
    .detail-htab-wrapper,
    .detail-subpage {
        width: 1024px;
    }
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 80em) {

    .detail-header,
    .detail-htab-wrapper,
    .detail-header-container,
    .main-page-table,
    .detail-htab-wrapper,
    .detail-subpage {
        width: 1200px;
    }
}

/* Largest devices such as desktops (1440px and up) */
@media only screen and (min-width: 90em) {

    .detail-header,
    .detail-htab-wrapper,
    .detail-header-container,
    .main-page-table,
    .detail-htab-wrapper,
    .detail-subpage {
        width: 1440px;
    }
}