:root {

    --default-element-radius: 16px;

    --typography-size-base: 14px;

    --primary-color: var(--primary-red-color);


    --primary-red-color: var(--color-ruby);
    --primary-red-accent-color: var(--color-ruby-light);
    --secondary-red-color: var(--color-ruby-dark);




    /* COLORS */

    /* key colors */

    .light-mode {
        --color-ruby: #ff0050;
        --color-ruby-accent: #ff4668;
        --color-ruby-light: #FFF2F6;
        --color-ruby-dark: #a5003c;
    }

    .dark-mode {
        --color-ruby: #ff0050;
        --color-ruby-accent: #ff4668;
        --color-ruby-light: #ff466825;
        --color-ruby-dark: #a5003c;
    }



    /* backgrouds */

    .light-mode {
        --primary-background-color: #F5F5F7;
        --secondary-background-color: #ffffff;
        --borders-color: #ededed;
        --shadow-color: #00000030;
    }

    .dark-mode {
        --primary-background-color: #222222;
        --secondary-background-color: #171717;
        --borders-color: #000000;
        --default-card-bg: #353537;
        --shadow-color: #FFFFFF30;
    }

    //    --background-active-navigation-tab: #ffd1d9;

    //input colors

    --disabled-input-bg:rgba(239, 239, 239, 0.3);
    --disabled-input-text:rgb(84, 84, 84);
    /* Tabs Colors */

    .light-mode {
        --tab-selected-primary-color: var(--color-ruby);
        --tab-selected-secondary-color: var(--color-ruby-light);
        --tab-hover-primary-color: #b1b1b1;
        --tab-hover-secondary-color: #e2e2e2;
    }

    .dark-mode {
        --tab-selected-primary-color: var(--color-ruby);
        --tab-selected-secondary-color: var(--color-ruby-light);
        --tab-hover-primary-color: #b1b1b1;
        --tab-hover-secondary-color: #e2e2e2;
    }


    /* text */

    .dark-mode {
        --primary-text-color: #ffffff;
        --secondary-text-color: #c8cbd1;
        --disabled-text-color: #767c8d;

    }

    .light-mode {
        --primary-text-color: #141719;
        --secondary-text-color: #767c8d;
        --disabled-text-color: #c8cbd1;
    }

    --placeholder-text-color: #d2d9dd;
    --icon-text-color:#767c8d;
    --ruby-90: #ffc2d5;

    // --default-text-black: #141719;
    // --placeholder-text-color: #d2d9dd;
    //  --icon-text-color: #767c8d;

    .dark-mode {
        --selected-table-row-color: #002330;
    }

    .light-mode {
        --selected-table-row-color: #d6f4ff;
    }

    /* alternatives */

    --color-orange: #ffa000;
    --color-orange-light: #fdcd7d;
    --color-orange-dark: #975f00;

    --color-gold: #ffd728;
    --color-gold-light: #fde891;
    --color-gold-dark: #a5780f;

    --color-rose: #ff78d2;
    --color-rose-light: #fdb9e6;
    --color-rose-dark: #a55078;

    --color-purple: #7b61ff;
    --color-purple-light: #baaefd;
    --color-purple-dark: #493a99;

    --color-info: #00b9ff;
    --color-info-light: #7dd9fd;
    --color-info-dark: #001e5a;

    --color-eco: #32d282;
    --color-eco-light: #96e6be;
    --color-eco-dark: #005a32;

    --color-black: #141719;
    --color-black-light: #ededed;
    --color-black-hover: #747474;


}